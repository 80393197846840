import Carousel from './carousel.js';

const start = document.querySelector('.start');
const left = document.querySelector('.start__left');
const right = document.querySelector('.start__right');
const center = document.querySelector('.start__logo');
const centerText = document.querySelector('.start__text');
const transitionButton = document.querySelector('.start__scroll');
let currentPosLeft = Number(window.getComputedStyle(left).left);
let currentPosRight = Number(window.getComputedStyle(right).right);
let currentCenterScale = 1;
let currentTextOpacity = 1;
const currentPosRightInit = currentPosRight;
const carouselOptions = {
  itemClass: 'home__content',
  currentClassSuffix: '--current',
  previousClassSuffix: '--prev',
  nextClassSuffix: '--next',
  dots: [
    {
      dotsClass: 'home__content-background',
      activeClassSuffix: '--active',
      trailingDots: false,
      clickable: false,
    },
    {
      dotsClass: 'ring__segment',
      activeClassSuffix: '--active',
      trailingDots: true,
      clickable: false,
    },
    {
      dotsClass: 'ring__circle',
      activeClassSuffix: '--active',
      trailingDots: true,
      clickable: {
        cycleSpeed: 0,
      },
    },
  ],
  controls: {
    buttons: {
      nextButtonClass: 'button--next',
      previousButtonClass: 'button--previous',
    },
    scroll: {
      treshold: 10,
    },
    keyboard: {
      keyNext: 39,
      keyPrevious: 37,
    },
    // autoScroll: {
    //     timer: 5000,
    // },
  },
};

const initiateCarousel = () => {
  new Carousel(carouselOptions);
};

const removeStart = (event) => {
  start.parentNode.removeChild(start);
  right.removeEventListener('transitionend', removeStart(event));
};

const homeTransition = () => {
  center.addEventListener('transitionend', (event) => removeStart(event));
  start.classList.add('start--transition');
  deleteEventListeners();
  initiateCarousel();
};

const handleWheel = (event) => {
  if (currentCenterScale > 1.20) {
    currentCenterScale = 1.20;
    homeTransition();
  } else if (currentCenterScale < 1) {
    currentCenterScale = 1;
    centerText.style.opacity = 1;
  } else if (currentCenterScale < 1.20) {
    currentPosLeft -= 2 * event.deltaY;
    currentPosRight -= 2 * event.deltaY;
    currentCenterScale += 0.01 * event.deltaY;
    currentTextOpacity -= 0.15 * event.deltaY;
    left.style.left = `${currentPosLeft}px`;
    right.style.right = `${currentPosRight}px`;
    center.style.scale = currentCenterScale;
    centerText.style.opacity = currentTextOpacity;
    document.querySelector('.start__scroll').classList.add('scroll--hidden');
  }
};

const handleKeyboard = (event) => {
  if (event.keyCode === 39 || event.keyCode === 40) {
    homeTransition();
  }
};

const handleTouch = (event) => {
  const startY = event.targetTouches[0].screenY;

  window.addEventListener('touchmove', (event2) => {
    const dY = event2.changedTouches[0].screenY - startY;
    if (dY > window.innerHeight * 0.5) {
      homeTransition();
    } else if (dY > 0) {
      currentPosLeft -= 2 * dY;
      currentPosRight -= 2 * dY;
      currentCenterScale += 0.00001 * dY;
      currentTextOpacity -= 0.15 * dY;
      left.style.left = `${currentPosLeft}px`;
      right.style.right = `${currentPosRight}px`;
      center.style.scale = currentCenterScale;
      centerText.style.opacity = currentTextOpacity;
      document.querySelector('.start__scroll').classList.add('scroll--hidden');
    }
  });

  window.addEventListener('touchend', (e) => {
    center.style.scale = 1;
  });
};

const deleteEventListeners = () => {
  transitionButton.removeEventListener('click', homeTransition);
  window.removeEventListener('keyup', handleKeyboard);
  window.removeEventListener('wheel', handleWheel);
  window.removeEventListener('touchstart', handleTouch);
};

const initEventListeners = () => {
  transitionButton.addEventListener('click', homeTransition);
  window.addEventListener('keyup', handleKeyboard);
  window.addEventListener('wheel', handleWheel);
  window.addEventListener('touchstart', handleTouch);
};

initEventListeners();
